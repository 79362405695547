import Api from '@/services/Api'

export default {
  index () {
    return Api().get('labs')
  },

  getAll(params) {
    return Api().get('labs', {params})
  },

  show (labId) {
    return Api().get(`labs/${labId}`)
  },

  search (params) {
    return Api().get('search', {params})
  },

  mylabs (userId) {
    return Api().get(`mylabs/${userId}`)
  },

  create (labData) {
    return Api().post('lab', labData)
  },

  update (labData) {
    return Api().post(`labs/${labData.id}`, labData)
  },

  delete (labId) {
    return Api().delete(`labs/${labId}`)
  }
}