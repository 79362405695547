<template>
      <v-parallax
        src="../assets/pic/background-labs.png"
        dark
        height="100%"
        fluid class="fill-height"
      > 
      <v-row gutters>
          <v-col md="12" lg="2">
             <filter-panel /> 
          </v-col>
          <v-col md="12" lg="8">
            <v-container>
                <lab-panel :labs="labs" :timeout="timeout"/>
              <v-pagination
                circle
                v-model="page"
                :length="totalPages"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
              ></v-pagination>
            </v-container> 
          </v-col>
        </v-row> 
    </v-parallax>
</template>

<script>
  import FilterPanel from './FilterPanel'
  import LabPanel from './LabPanel'
  import LabService from '@/services/LabsService'
  export default {
    components: {
      FilterPanel,
      LabPanel
    },
    data () {
      return {
        raw_query: '',
        lab_types: [],
        labs: null,
        page: 1,
        totalPages: 0,
        pageSize: 5,
        timeout: 100
      }
    },
    async mounted () {
      this.raw_query = this.$store.state.search_query
      this.lab_types = this.$store.state.search_types
      this.retrieveLabs();
    },
    methods: {
      retrieveLabs() {
        const params = {
          raw_query: this.raw_query,
          lab_types: this.lab_types,
          page: this.page - 1,
          size: this.pageSize
        }
        LabService.search(params).then((response) => {
          this.labs = response.data.labs
          this.totalPages = response.data.totalPages
        }).catch(() => {
          this.labs = []
        })
        
      },
      handlePageChange (page) {
        this.page = page;
        this.retrieveLabs();
      },
      isSameLabTypes(array1, array2) {
        return array1.sort().join(',') === array2.sort().join(','); 
      },
      initPage() {
        this.page = 1;
      }
    },
    watch: {
      '$store.state.search_query': function (query) {
        this.raw_query = query
        this.initPage();
        this.retrieveLabs();
      },
      '$store.state.search_types': function (types) {
        this.lab_types = types;
        this.initPage();
        this.retrieveLabs();
      }
    }
  };
</script>
