<template>
<v-container class="fill-height" fluid>
  <v-row dense align="start" justify="center">
  <v-col cols="12">
  <v-card
      class="mx-auto"
    >
  
  <v-card-text class="display-1">
      {{message}}
  </v-card-text>

  </v-card>
  </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  data: () => ({
    message: '',
  }),
  async mounted () {
    this.message = this.$store.state.route.params.message
  },
}
</script>

<style scoped>
</style>