<template>
      <v-card>
        <content-placeholders :rounded="true">
          <content-placeholders-img/>
          <content-placeholders-text :lines="3" class="v-card__title display-1"/>
          <content-placeholders-text :lines="2" class="v-card__subtitle"/>
        </content-placeholders>
        <v-divider> </v-divider>
        <content-placeholders :rounded="true">
          <content-placeholders-heading class="v-card__title subtitle-1 font-weight-bold"/>
          <content-placeholders-text :lines="2" class="v-card__text text--primary"/>
        </content-placeholders>
        <v-divider> </v-divider>
        <content-placeholders :rounded="true">
          <content-placeholders-heading class="v-card__title subtitle-1 font-weight-bold"/>
          <content-placeholders-text :lines="5" class="v-card__text text--primary"/>
        </content-placeholders>
      </v-card>
</template>
<script>
import Vue from 'vue'
import VueContentPlaceholders from 'vue-content-placeholders'


Vue.use(VueContentPlaceholders)

export default {
};
</script>
<style scoped lang="scss">
  @import "~vue-content-placeholders/src/styles.scss";
  .vue-content-placeholders-img {
    height: 500px;
  }
</style>