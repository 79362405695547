<template>
  <div>
    <div class="header">
      <status-icon :connected="user.connected" />{{ user.username }}
    </div>
    <v-divider />
    <div class="chat-box">
      <div v-for="(message, index) in user.messages" :key="index" :class="{'top-padding': displaySender(message,index)}">
        <v-card theme="dark" outlined color="transparent" class="message-card text-black">
          <div v-if="displaySender(message,index)">
          <span  class="name-text" >{{ message.fromSelf ? self.firstname + " " + self.lastname : user.username }}</span>
          <span class="timestamp-text">   {{ formatDate(message.timestamp) }}</span>
          </div>
          <v-card-text class="font-weight-medium no-padding text-render-white-space">{{ message.message }}</v-card-text>
        </v-card>
      </div>
    </div>
    <v-divider />
    <div class="chat-input-box">
      <form @submit.prevent="onSubmit" class="form center">
        <textarea v-model="input" placeholder="Your message..." class="input" @keydown.enter.prevent="onSubmit()"/>
        <button :disabled="!isValid" class="send-button">Send</button>
      </form>
    </div>
  </div>
</template>

<script>
import StatusIcon from "./StatusIcon";
export default {
  name: "MessagePanel",
  components: {
    StatusIcon,
  },
  props: {
    user: Object,
    self: Object,
  },
  data() {
    return {
      input: "",
    };
  },
  methods: {
    onSubmit() {
      this.$emit("input", this.input);
      this.input = "";
    },
    displaySender(message, index) {
      return (
        index === 0 ||
        this.user.messages[index - 1].fromSelf !==
          this.user.messages[index].fromSelf
      );
    },
    formatDate(date) {
      const date_obj = new Date(date);
      return date_obj.toISOString().replace(/T/, ' ').replace(/\..+/, '');
    }
  },
  computed: {
    isValid() {
      return this.input.length > 0;
    },
  },
};
</script>

<style scoped>
.header {
  line-height: 40px;
  padding: 10px 20px;
  border-bottom: 1px solid #dddddd;
  font-weight: bold;
  font-size: large;
}
.input {
  width: 80%;
  resize: none;
  padding: 10px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #000;
}
.send-button {
  vertical-align: top;
}
.top-padding{
  padding-top: 15px;
}
.no-padding {
  padding: 0;
}
.chat-box{
  overflow: auto;
  height: 400px;
  padding-left: 30px;
  padding-right: 30px;
}
.chat-text {
  color: black;
}
.chat-input-box{
  padding-top: 30px;
}
.center {
  margin:auto;
  text-align: center;
}
.name-text {
  font-weight: bold;
  font-size: large;
}
.timestamp-text {
  font-size: small;
  opacity: 0.5;
}
</style>