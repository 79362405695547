module.exports = {
  apiKey: {
    google: process.env.GOOGLE_API_KEY || 'AIzaSyCtbBLCBfGScn6Sdo8Trsime4TjXSAyt-E'
  },
  S3Key: {
    accessKeyId: process.env.S3ACCESSID || 'AKIA4DW5ZBZSUADTFJMU',
    secretAccessKey: process.env.S3SECRET || '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'
  },
  backend: {
    baseUrl: process.env.VUE_APP_BACKEND_URL || 'http://localhost:8081/',
    chatURL: process.env.VUE_APP_BACKENDCHAT_URL || 'http://localhost:3000/'
  }
}