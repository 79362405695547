<template>
  <div >
    <v-row class="chatuser" @click="onClick" :class="{ selected: selected }">
        <v-col cols="auto">
          <v-icon>mdi-chat</v-icon>
        </v-col>
        <v-col cols="auto">
          <div class="name">
            {{ user.username }} {{ user.self ? " (yourself)" : "" }}
          </div>
        <!-- <div class="status">
          <status-icon :connected="user.connected" />{{ status }}
        </div> -->
        </v-col>
    </v-row>
    <!-- <div v-if="user.hasNewMessages" class="new-messages">!</div> -->
    <v-divider
    ></v-divider>
  </div>
</template>

<script>
// import StatusIcon from "./StatusIcon";
export default {
  name: "ChatUser",
  // components: { StatusIcon },
  props: {
    user: Object,
    selected: Boolean,
  },
  methods: {
    onClick() {
      this.$emit("select");
    },
  },
  computed: {
    status() {
      return this.user.connected ? "online" : "offline";
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #f2f2f2;
}
.chatuser {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.description {
  display: inline-block;
}
.status {
  color: #92959e;
}
.new-messages {
  color: white;
  background-color: red;
  width: 20px;
  border-radius: 5px;
  text-align: center;
  float: right;
  margin-top: 10px;
}
</style>