<template>
  <div>
    <input multiple type="file" ref="files" accept="image/*" @change="selectImages" id="file-input">
    <div class="file-lists" v-if="fileMetadatas.length">
      <ul>
        <li v-for="(file, index) in fileMetadatas" :key="index">
          <span class="body">{{file.name}}</span> 
          <span class="remove" v-on:click="removeItem(index)">x</span>
          <v-icon color="green" v-if="file.uploadStatus===1">mdi-checkbox-marked-circle</v-icon></li>
      </ul>
    </div>
    <div>
      <v-btn class="mr-1" @click="uploadImages">upload</v-btn>
    </div>
  </div>
</template>

<script>
import S3 from 'aws-s3';
import config from '../config'

export default {
  data() {
    return {
      files: [],
      fileMetadatas: [],
      uploading: false,
    }
  },
  compute: {
  },
  methods: {
    config() {
      return {
        bucketName: 'labspace-images',
        dirName: 'images', /* optional */
        region: 'us-east-1',
        accessKeyId: config.S3Key.accessKeyId,
        secretAccessKey: config.S3Key.secretAccessKey,
        s3Url: 'https://labspace-images.s3.amazonaws.com/', /* optional */
      }
    },
    S3Client() {
      return new S3(this.config())
    },
    hashCode(s){
      return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              
    },
    selectImages() {
      const files = this.$refs.files.files;
      this.fileMetadatas = [...this.fileMetadatas, ...Array.from(files).map(file => ({
        name: file.name,
        size: file.size,
        s3Name: (this.hashCode(file.lastModified + file.name) >>> 0).toString(),
        uploadStatus: 0
      }))]
      this.files = [...this.files, ...files]
    },
    removeItem: function(index){
      if (this.fileMetadatas[index].uploadStatus === 1) {
        this.obj["urls"].splice(index, 1)
      }
      this.fileMetadatas.splice(index, 1);
      this.files.splice(index, 1);
    },
    uploadImages() {
      this.uploading = true
      for (var i = 0; i < this.files.length; i++) {
        var file_metadata = this.fileMetadatas[i]
        if (file_metadata.uploadStatus === 1) {
          continue;
        }
        this.S3Client()
        .uploadFile(this.files[i], file_metadata.s3Name)
        .then(data => {
          if (this.obj["urls"]) {
            this.obj["urls"].push(data.key)
          } else {
            this.obj["urls"] = [data.key]
          }
        })
        .catch(err => {
          file_metadata.uploadStatus = 2
          console.error(err)
        })
        .finally(()=>{
          file_metadata.uploadStatus = 1
        })
      }
    }
  },
  props: {
    fieldName: {
      type: String,
      required: false,
    },
    inputFiles: {
      type: Array,
      required: false,
    },
    obj: {
      type: Object,
      required: true
    }
  },
  watch: {
    inputFiles: function () {
      if (this.inputFiles) {
        for (var i = 0; i< this.inputFiles.length;i++) {
          this.files.push({})
          this.fileMetadatas.push({
            name: this.inputFiles[i],
            size: 0,
            s3Name: this.inputFiles[i],
            uploadStatus: 1
          })
          if (this.obj["urls"]) {
            this.obj["urls"].push(this.inputFiles[i])
          } else {
            this.obj["urls"] = [this.inputFiles[i]]
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.dynamic-items{
  display: inline-block;
  width: 200px;
  border: 1px solid transparent;
  padding: 8px;
  font-size: 16px;
  vertical-align:middle;
}

.dynamic-items:hover{
  border:1px solid #c4c4c4;
  border-radius: 10px;
}

.remove{
  cursor:pointer;
  display:inline-block;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  padding:0px 4px;
}
.remove:hover{
  background: #3cb0fd;
}
</style>