import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from './config'
import vuetify from './plugins/vuetify'
import {sync} from 'vuex-router-sync'
import store from '@/store/store'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false

sync(store, router)

Vue.use(VueGoogleMaps, {
  load: {
    key: config.apiKey.google,
    region: 'US',
    language: 'en'
  }
})

new Vue({
  router,
  vuetify,
  Vuelidate,
  store,
  render: h => h(App)
}).$mount('#app')