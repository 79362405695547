<template>
  <v-container>
    <v-row gutters>
      <v-col class="mt-8 ml-12">
        <v-card
            elevation="12"
            color="rgb(255, 255, 255, 0.8)"
        >
            <v-row no-gutters>
                <v-list
                    flat
                    subheader
                    two-line
                  >
                    <v-subheader>
                      Filters
                    </v-subheader>
                    <v-divider> </v-divider>    
                    <v-list-item-group                    
                      multiple
                      v-model="selectedTypes"
                      @change="search()"
                    >
                      <v-list-item
                        v-for="type in labTypes"
                        :key="type.id"
                        :value="type"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox 
                              :input-value="active"
                              :true-value="type"
                            >                            
                            </v-checkbox>
                          </v-list-item-action>
              
                          <v-list-item-content>
                            <v-list-item-title v-text="type"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
            </v-row>          
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({  
      labTypes: ['Lab Leasing', 
                  'Incubator/Accelerator', 
                  'Other Shared Spaces',
                  'Research Services Offered',
                  'Properties'],  
      selectedTypes: [],
      formattedTypes: ''
    }),
    async mounted () {
      this.selectedTypes = this.$store.state.search_types
    },
    methods: {
      search () {
        this.$store.dispatch('setSearchTypes', this.selectedTypes)
      },
    }
  }
</script>