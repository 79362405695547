<template>
    <v-container>
      <v-row>
        <v-col         
                sm="12"
                md="12"
                lg="12"
                >
          <v-row dense align="start" justify="center"
          v-if="shownLabs && shownLabs.length">
            <v-flex
              sm10
              md8
              lg8>
            <transition-group :name="transitionType"
            >
            <v-col
              v-for="lab in shownLabs"
              :key="lab.id"
              :cols="12"
              >
                <lab-card :lab="lab" />
            </v-col>
            </transition-group>
          </v-flex>
          </v-row>
          <v-row v-if="!shownLabs"
              align="center"
              justify="center">
              <v-progress-circular
              indeterminate
              color="primary"
              class="bottom"
            ></v-progress-circular>
          </v-row>
        </v-col>  
      </v-row>        
    </v-container>
</template>

<script>
  import LabCard from './LabCard'
  export default {
    props: ['labs', 'timeout'],
    components: {
      LabCard
    },
    data () {
        return {
          shownLabs: null,
          transitionType: 'list',
          currentInterval: null
        }
    },
    methods: {
      bottomVisible() {
        const scrollY = window.scrollY
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        const bottomOfPage = visible + scrollY >= pageHeight
        return bottomOfPage || pageHeight < visible
      },
      async addToShownLab() {
          const items = Array.from(this.$props.labs, (x) => {
            return x;
          })
          this.currentInterval = setInterval(() => {
            if (!items) {
              clearInterval(this.currentInterval)
              this.transitionType = 'none'
            } else {
              if (!this.shownLabs) {
                this.shownLabs = []
              }
              if (!items.length) {
                clearInterval(this.currentInterval)
                this.transitionType = 'none'
              } else {
                this.transitionType = 'list'                
                this.shownLabs.push(items.shift())
              }
            }
          }, this.$props.timeout)
      },
    },
    watch: {
      labs: {
        handler() {
          clearInterval(this.currentInterval);
          this.shownLabs = null;
          this.addToShownLab();

        } 
      }
    },
  };

</script>

<style scoped>
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active, .list-leave-active {
    transition: 2s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(50px);
  }
</style>