<template>
    <v-container>
      <v-hover>
        <template v-slot:default="{ hover }">
        <v-card
          class="card-lab transition-swing"
          color="rgb(255, 255, 255, 0.8)"
          :class="`elevation-${hover ? 24 : 6}`"
          @click="navigateTo({
                    name: 'lab-detail',
                    params: {
                      msg: lab,
                      labId: lab.id
                    }
                  })"
        >
            <v-img
              v-if="lab.image_urls && lab.image_urls.length"
              :src="getImageUrl(lab.image_urls)"
              class="white--text align-end img-circle"
              :class="`elevation-${hover ? 12 : 6}`"
              position="left"
            >
            </v-img>  
            <div :class="lab.image_urls && lab.image_urls.length && 'img-exist-padding'">
              <v-app-bar 
                      flat
                      color="rgb(255, 255, 255, 0)">
                <v-toolbar-title 
                class="lab-title font-weight-light text-truncate d-inline-block" 
                color="rgb(255, 255, 255, 0.8)"
                v-text="lab.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn 
                  color="black"                  
                  icon
                  @click="navigateTo({
                    name: 'lab-detail',
                    params: {
                      msg: lab,
                      labId: lab.id
                    }
                  })">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                <v-btn 
                  v-if="lab.editable"
                  color="black"
                  text
                  @click="navigateTo({
                    name: 'create-lab',
                    params: {
                      msg: lab,
                    }
                  })"
                >
                Edit
                </v-btn>
              </v-app-bar>  
              <v-card-text 
                v-text="lab.description" 
                class="card-text-description"></v-card-text>
              
              <v-chip-group
                column
                class="ma-4"
              >
                <v-chip
                  color="primary"
                  pill
                  outlined
                  v-for="(item, i) in lab.category"
                  :key="i"
                >
                  <v-icon left>
                    mdi-label
                  </v-icon>
                  {{ item }}
                </v-chip>
              </v-chip-group>

              <v-card-subtitle 
                class="font-weight-bold text-right">
                {{lab.full_address}}
                <v-spacer></v-spacer>
                <div class="lab-distance font-weight-light">
                  <span v-if="lab.distance_in_km" :class="lab.distance_in_km <= 10 ? 'close-color' : (lab.distance_in_km <= 100 ? 'near-color' : 'far-color')">{{formatDistance(lab.distance_in_km)}} miles</span>
                </div>
              </v-card-subtitle>
            </div>              
        </v-card>
        </template>
      </v-hover>
    </v-container> 
</template>

<script>
  export default {
    props: ['lab'],
    data () {
        return {
          shownLabs: null,
          transitionType: 'list'
        }
    },
    methods: {
      navigateTo (route) {
        this.$router.push(route)
      },
      getImageUrl (image_urls) {
        if (image_urls.length > 0) {
          return image_urls[0]
        } else {
          return ''
        }
      },
      formatDistance(distance) {
        return Math.round(distance * 0.62137273, 1)
      }
    }
  };
</script>

<style scoped>
  .card-lab {
    text-overflow: ellipsis;
  }
  .img-circle {
    position: absolute;
    display: block;
    top:  calc(50% - 100px);
    left: -100px;
    height: 200px;
    width: 200px;
    object-fit: cover;
    border:  3px solid white;
    border-radius: 50% !important;
    z-index: 1;
  }
  .img-exist-padding {
    padding-left: 100px;
  }
  .card-text-description {
    -webkit-line-clamp: 4;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    padding-bottom: 0px;
    margin-bottom: 16px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .lab-title {
    font-size: 1.6rem;
  }
  .lab-distance {
    font-size: 1.4rem;
  }
  .close-color {
    color: rgba(67,214,43,1);
  }
  .near-color {
    color: rgba(255,184,0,1);
  }
  .far-color {
    color: rgba(255,0,0,1);
  }
</style>