<template>
  <v-app>
    <page-header />
      <v-content >
        <router-view :key="$route.fullPath"></router-view>
      </v-content>
      <page-footer />
  </v-app>
</template>

<script>
import PageHeader from '@/components/Header.vue'
import PageFooter from '@/components/Footer.vue'

export default {
  name: 'app',
  components: {
    PageHeader,
    PageFooter
  },
  created() {
    if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
      window.location.href = 'https://www.lablocale.com'
    }
  }
};
</script>

<style>
.v-label {
    font-size: 1.4em;
}
</style>
