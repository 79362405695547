import {io} from 'socket.io-client'
import config from '@/config'

export default class ChatService {
  socket;
  constructor() {
    this.socket = io(config.backend.baseUrl, {
      autoConnect: false
    });
    this.socket.on('connect', () => {
      console.log('Connected socket... ')
    });
  }

  connect(userInfo) {
    this.socket.auth = userInfo
    this.socket.connect()
    console.log('Connecting socket... ' + config.backend.baseUrl)
  }

  subscribeMsg(cb) {
    if (!this.socket) return(false)
    this.socket.on('message', msg => {
      return cb(null, msg)
    })
  }

  // client will call when wants to start a new conversation.
  joinChat(dest) {
    if (this.socket) {
      this.socket.emit('join', dest)
    } else {
      console.log("join socket lost")
    }
  }

  sendMsg(dest, message, cb) {
    if (this.socket) {
      this.socket.emit('message', {dest, message}, cb);
      return true
    } else {
      console.log("socket lost")
    }
    return false
  }

  disconnect() {
    console.log('Disconnect')
    if (this.socket) {
      console.log('Disconnect here')
      this.socket.disconnect();
    }
  }
}