import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    token: null,
    user: null,
    isUserLoggedIn: !!localStorage.getItem('token'),
    search_query: '',
    search_types: []
  },
  getters: {
    user: state => {
      if (state.user) {
        return state.user
      }
      const user_str = localStorage.getItem('user');
      if (user_str) {
        try {
          return JSON.parse(user_str)
        } catch (e) {
          console.log('user is not valid when parsing')
          localStorage.removeItem('user')
        }
      }
      return null;
    },
    token: state => {
      if (state.token) {
        return state.token
      }
      return localStorage.getItem('token')
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
        state.isUserLoggedIn = true
      } else {
        localStorage.removeItem('token')
        state.isUserLoggedIn = false 
      }
    },
    setUser (state, user) {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      } else {
        localStorage.removeItem('user')
        state.user = null
      }
    },
    setSearchQuery(state, query) {
      state.search_query = query
    },
    setSearchTypes(state, types) {
      state.search_types = types
    }
  },
  actions: {
    setToken ({commit}, token) {
      commit('setToken', token)
    },
    setUser ({commit}, user) {
      commit('setUser', user)
    },
    setSearchQuery ({commit}, query) {
      commit('setSearchQuery', query)
    },
    setSearchTypes ({commit}, types) {
      commit('setSearchTypes', types)
    }
  }
})