<template>
  <v-parallax
        src="../assets/pic/background-labs.png"
        dark
        height="100%"
        fluid class="fill-height"
      > 
  <v-container class="fill-height" fluid>
    <v-row align="start" justify="center">
        <v-btn
          v-if="isEditable()"
          color="primary"
          :to="{
            name: 'lab-edit',
            params () {
              return {labId: lab.id}
            }
          }"
          dark
          medium
          absolute
          right
          middle
          fab>
          <v-icon>edit</v-icon>
        </v-btn>
        <v-col v-if="!isLabLoaded()">
          <view-lab-place-holder />
        </v-col>
        <v-col v-else>
          <v-row
            justify="center"
          >
            <v-col 
              v-if="isImageUrlPresent(lab.image_urls)"            
              sm="5"
              md="5"
              lg="5"
              >
              <v-card
                height="100%"
                class="elevation-6"
              >
                <v-carousel
                  height="auto"
                  class="pa-4"
                  show-arrows-on-hover
                  hide-delimiter-background
                >
                  <v-carousel-item
                    v-for="(image,i) in lab.image_urls"
                    :key="i"
                  >
                  <v-img                     
                    :src="image" 
                    aspect-ratio="1"
                  />
                  </v-carousel-item>
                </v-carousel>
                <v-divider> </v-divider>
                <!-- <v-row 
                  no-gutters
                  v-if="!lab.is_active">
                  <v-card-title class="subtitle-1 font-weight-bold red--text text--darken-1">
                    Lab Profile Unclaimed by Owner
                  </v-card-title>
                </v-row> -->
                <v-row no-gutters v-if="lab.hours">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Hours of Operation
                  </v-card-title>
                  <v-card-text class="text--primary text-render-white-space">
                    {{formatText(lab.hours, 'No hours of operation provided')}}
                  </v-card-text>
                </v-row>
                <!-- <v-row no-gutters v-if="lab.price">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Price Range
                  </v-card-title>
                  <v-card-text class="text--primary">
                    {{formatText(lab.price, 'No prices available')}}
                  </v-card-text>
                </v-row> -->
                <v-row no-gutters v-if="lab.space">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Available Space (Sq. Ft)
                  </v-card-title>
                  <v-card-text class="text--primary text-render-white-space">
                    {{formatText(lab.space, 'No space information provided')}}
                  </v-card-text>
                </v-row>
              </v-card>
            </v-col>
            <v-col
              :sm="isImageUrlPresent(lab.image_urls) ? 5 : 10"
              :md="isImageUrlPresent(lab.image_urls) ? 5 : 10"
              :lg="isImageUrlPresent(lab.image_urls) ? 5 : 10"
            >
              <v-card
                height="100%"
                class="elevation-6"
              >
              <v-row>
                <v-btn
                    class="contact-us"
                    @click="goBack"
                  absolute
                  top
                  right
                  fab
                  small
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>

                <v-row no-gutters>
                  <v-card-title class="display-1 font-weight-light">
                    <span class="text-render-white-space">
                      {{lab.name}}
                      <v-btn
                        :href="lab.website"
                        elevation="2"
                        fab
                        icon
                        raised
                        small
                        >
                          <v-icon>mdi-link-variant</v-icon>
                      </v-btn>
                    </span>
                  </v-card-title>              
                </v-row>    
                <v-card-title class="subtitle-1 font-weight-bold">
                  Description
                </v-card-title>

                <v-card-text class="text--primary text-render-white-space">
                  {{lab.description}}
                </v-card-text>
                <v-btn
                      class="contact-us"
                      @click="goChat"
                      elevation="5"
                      color="primary"
                      fab
                      icon                      
                      raised
                      medium
                      absolute
                      >
                      <v-icon class="contact-us-icon">mdi-message-text</v-icon>
                    </v-btn>
                <v-divider> </v-divider>

                <v-row no-gutters>
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Available Equipment
                  </v-card-title>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    class="pl-4 pr-4 pb-4"
                  >
                    <span v-if="!lab.equipments || lab.equipments.length == 0">No equipment available</span>
                    <v-chip-group
                      v-else
                      active-class="primary--text"
                      column
                    >
                      <v-chip outlined
                       v-for="(item, i) in lab.equipments"
                       :key="i"
                       >
                       {{item}}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
                <v-divider> </v-divider>
                <v-row no-gutters>
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Research Services Offered
                  </v-card-title>
                </v-row>
                <v-row no-gutters>
                  <v-list-item-group                    
                    class="ma-4"
                  >
                    <v-list-item
                      color="primary"
                      outlined
                      v-for="(item, i) in lab.services"
                      :key="i"
                    >
                    <v-list-item-icon>
                      <v-icon>mdi-circle-small</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-row>

                <v-divider> </v-divider>
                <v-row no-gutters>
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Pricing Information
                  </v-card-title>
                  <v-card-text class="text--primary text-render-white-space">
                    {{formatText(lab.price, 'No additional information')}}
                  </v-card-text>
                </v-row>

                <v-divider> </v-divider>
                <v-row no-gutters>
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Additional Information
                  </v-card-title>
                  <v-card-text class="text--primary text-render-white-space">
                    {{formatText(lab.notes, 'No additional information')}}
                  </v-card-text>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            class="pt-4"
            justify="center"
          >
            <v-col
              cols="10"
              sm="10"
              md="10"
              lg="10">
              <v-card
                class="elevation-6"
              >

                <v-card-title class="subtitle-1 font-weight-bold">
                  Location
                </v-card-title>
                <v-card-subtitle>
                  {{lab.full_address}}
                </v-card-subtitle>

                <v-row>
                  <v-col>
                    <gmap-map   
                      class="ml-4 mr-4 mb-1"
                      :center="center"
                      :zoom="12"
                      style="max-width:100%; height: 30vh;"
                    >
                      <gmap-marker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        @click="center=m.position"
                      ></gmap-marker>
                    </gmap-map>
                  </v-col>
                </v-row>

              </v-card>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
  </v-container>
        </v-parallax>
</template>

<script>
  import {mapState} from 'vuex'
  import LabService from '@/services/LabsService'
  import AuthenticationService from '@/services/AuthenticationService'
  import ViewLabPlaceHolder from './ViewLabPlaceHolder'
  export default {
    props: ['msg'],
    data: () => ({
      lab: {},
      lab_admin: null,
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
    }),
    components: {
      ViewLabPlaceHolder
    },
    async mounted() {
        if (this.msg) {
          this.lab = this.msg 
        } else {
          const labId = this.$store.state.route.params.labId
          this.lab = (await LabService.show(labId)).data
        }
        if (this.lab.lat && this.lab.lng) {
          this.center = {lat: this.lab.lat, lng: this.lab.lng}
          this.markers.push({ position: this.center }); 
        }
        if (this.lab.userId) {
          this.lab_admin = (await AuthenticationService.getUser(this.lab.userId)).data
        }
    },
    computed: {
      ...mapState([
        'isUserLoggedIn',
        'user'
      ])
    },
    methods: {
      isImageUrlPresent(image_urls) {
        return (image_urls && image_urls.length > 0);          
      },
      FirstHalf(items) {
        if (!items) {
          return []
        }
        return items.slice(0, items.length / 2 + 1)
      },
      SecondHalf(items) {
        if (!items) {
          return []
        }
        return items.slice(items.length / 2 + 1)
      },
      isEditable() {
        return this.isUserLoggedIn && this.$store.getters.user.id === this.lab.userId
      },
      isLabLoaded() {
        return Object.keys(this.lab).length > 0
      },
      isUnclaimed() {
        return false
      },
      formatText(text, defaultText='None') {
        if (text && text != '') {
          return text
        } else {
          return defaultText
        }
      },
      navigateTo (route) {
        this.$router.push(route)
      },
      goChat () {
        var lab_admin = this.lab_admin
        if (this.$store.getters.user.id === this.lab.userId) {
          lab_admin = null
        }
        var route = {
                      name: 'chat',                          
                      params: {
                        lab_dest: this.lab,
                        lab_admin: lab_admin
                      }}
        this.$router.push(route)
      },
      goBack() {
        this.$router.back()
      }
    },
  };
</script>

<style scoped>

  .text-render-white-space {
    /*white-space: pre-wrap;*/

    white-space: normal;
    word-break: keep-all;
  }

  .title-wrap-text {
    white-space: normal;
  }
  .contact-us {
    background-color: white;
    top:  50px;
    right:  -28px;
  }
  .contact-us-icon {
    color: blue !important;
  }
</style>