var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"card-lab transition-swing",class:`elevation-${hover ? 24 : 6}`,attrs:{"color":"rgb(255, 255, 255, 0.8)"},on:{"click":function($event){return _vm.navigateTo({
                name: 'lab-detail',
                params: {
                  msg: _vm.lab,
                  labId: _vm.lab.id
                }
              })}}},[(_vm.lab.image_urls && _vm.lab.image_urls.length)?_c('v-img',{staticClass:"white--text align-end img-circle",class:`elevation-${hover ? 12 : 6}`,attrs:{"src":_vm.getImageUrl(_vm.lab.image_urls),"position":"left"}}):_vm._e(),_c('div',{class:_vm.lab.image_urls && _vm.lab.image_urls.length && 'img-exist-padding'},[_c('v-app-bar',{attrs:{"flat":"","color":"rgb(255, 255, 255, 0)"}},[_c('v-toolbar-title',{staticClass:"lab-title font-weight-light text-truncate d-inline-block",attrs:{"color":"rgb(255, 255, 255, 0.8)"},domProps:{"textContent":_vm._s(_vm.lab.name)}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","icon":""},on:{"click":function($event){return _vm.navigateTo({
                name: 'lab-detail',
                params: {
                  msg: _vm.lab,
                  labId: _vm.lab.id
                }
              })}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),(_vm.lab.editable)?_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){return _vm.navigateTo({
                name: 'create-lab',
                params: {
                  msg: _vm.lab,
                }
              })}}},[_vm._v(" Edit ")]):_vm._e()],1),_c('v-card-text',{staticClass:"card-text-description",domProps:{"textContent":_vm._s(_vm.lab.description)}}),_c('v-chip-group',{staticClass:"ma-4",attrs:{"column":""}},_vm._l((_vm.lab.category),function(item,i){return _c('v-chip',{key:i,attrs:{"color":"primary","pill":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(item)+" ")],1)}),1),_c('v-card-subtitle',{staticClass:"font-weight-bold text-right"},[_vm._v(" "+_vm._s(_vm.lab.full_address)+" "),_c('v-spacer'),_c('div',{staticClass:"lab-distance font-weight-light"},[(_vm.lab.distance_in_km)?_c('span',{class:_vm.lab.distance_in_km <= 10 ? 'close-color' : (_vm.lab.distance_in_km <= 100 ? 'near-color' : 'far-color')},[_vm._v(_vm._s(_vm.formatDistance(_vm.lab.distance_in_km))+" miles")]):_vm._e()])],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }