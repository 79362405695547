<template>
<v-container class="align-start" fill-height fluid>
  <v-row align="start">
    <v-col cols="3" class="left-panel" fill-height>
      <div class="left-panel">
          <div
            v-if="user"
          > {{ username }} </div>
        <v-divider></v-divider>
        <ChatUser
          v-for="user in users"
          :key="user.id"
          :user="user"
          :selected="selectedUser === user"
          @select="onSelectUser(user)"
        />
      </div>
    </v-col>
    <v-divider
      vertical
    ></v-divider>
    <v-col xs12 sm8 md4>
      <message-panel
        v-if="selectedUser"
        :user="selectedUser"
        :self="user"
        @input="onMessage"
        class="right-panel"
      />
    </v-col>
  </v-row> 
</v-container>
</template>

<script>
  import ChatService from '@/services/Chat'
  import ChatUser from "./ChatUser";
  import MessagePanel from "./MessagePanel";

  export default {
    name: 'Chat',
    components: { ChatUser, MessagePanel },
    data: () => ({
      socket: new ChatService(),
      target_user: null,
      message: '',
      user: null,
      dest: '',
      last_dest: '',
      messages: [],
      // only contains id and username
      users: [],
      selectedUser: null,
    }),
    created() {
      this.user = this.$store.getters.user
      this.socket.connect({ 
        user: {
          id: this.user.id,
        },
        token: this.$store.getters.token 
      });
      this.target_user = this.$store.state.route.params.lab_admin
      this.socket.socket.on('users', (users) => {
        console.log('receive users')
        console.log(users)
        users.forEach((user) => {
          user.messages.forEach((message) => {
            message.fromSelf = message.from === this.user.id
          })
          for (let i = 0 ; i < this.users.length; i++) {
            let existingUser = this.users[i]
            if (existingUser.id === user.id) {
              existingUser.connected = user.connected
              existingUser.messages = user.messages
              // hasNewMessages depends on the timestamp of the last read timestamp
              return
            }
          }
          this.users.push(user)
        })
        if (this.target_user) {
          var is_found = false
          for(let i = 0; i < this.users.length; i++) {
            if (this.target_user.id === this.users[i].id) {
              this.selectedUser = this.users[i]
              is_found = true
              break;
            }
          }
          if (!is_found) {
            this.selectedUser = {
              id: this.target_user.id,
              username: this.target_user.firstname + ' ' + this.target_user.lastname
            }
            this.users.push(this.selectedUser)
          }
          this.target_user = null
        }
        this.users.sort((a,b) => { return a.id < b.id})
      });

      this.socket.subscribeMsg((err, data) => {
        for(let i = 0; i < this.users.length; i++) {
          let user = this.users[i]
          if (user.id === data.from.id) {
            user.messages.push({
              fromSelf: false,
              message: data.msg
            })
            if (user !== this.selectedUser) {
              user.hasNewMessages = true;
            }
            break
          }
        }
      });
    },
    beforeUnmount() {
      this.socket.disconnect();
    },
    methods: {
      onMessage (content) {
        if (this.selectedUser) {
          const dest = this.selectedUser.id
          this.socket.sendMsg(dest, content, (cb) => {
            console.log(cb)
            this.selectedUser.messages.push({
              fromSelf: true,
              message: content,
              timestamp: new Date().toISOString()
            })
          })
        }
      },
      onSelectUser(user) {
        this.selectedUser = user;
        user.hasNewMessages = false;
      },
      clear () {
        this.socket.disconnect();
        this.message = ''
      },
    },
    computed: {
      username() {
        return this.user.firstname + " " + this.user.lastname
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.left-panel {
  background-color: #ffffff;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
</style>
