<template>
  <v-parallax
    src="../assets/pic/background-labs.jpg"
    dark
    height="100%"
    fluid class="fill-height"
  > 
    <lab-panel :labs="labs" />
  </v-parallax>
</template>

<script>
  import LabPanel from './LabPanel'
  import LabService from '@/services/LabsService'
  export default {
    components: {
      LabPanel
    },
    data () {
      return {
        labs: null
      }
    },
    async mounted () {
      const userId = this.$store.state.route.params.userId
      this.labs = (await LabService.mylabs(userId)).data
      if (this.labs) {
        this.labs.forEach(element => {
          element['editable'] = true
        });
      }
    },
  }
</script>
