import Vue from 'vue'
import store from '@/store/store'
import Router from 'vue-router'
import Home from '@/components/Labs.vue'
import LogIn from '@/components/LogIn.vue'
import Forgot from '@/components/Forgot.vue'
import Reset from '@/components/ResetPwd.vue'
import Register from '@/components/Register.vue'
import ViewLab from '@/components/ViewLab.vue'
import EditLab from '@/components/EditLab.vue'
import MyLabs from '@/components/MyLabs.vue'
import CreateLab from '@/components/NewLab.vue'
import LabContactForm from '@/components/LabContactForm.vue'
import ConfirmationPage from '@/components/Confirmation.vue'
import LabSearch from '@/components/LabSearch.vue'
import AboutUs from '@/components/AboutUs.vue'
import Terms from '@/components/Terms.vue'
import Chat from '@/components/Chat.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about-us',
      name: 'AboutUs',
      component: AboutUs
    },
    {
      path: '/terms-conditions',
      name: 'Terms',
      component: Terms
    },
    {
      path: '/login',
      name: 'login',
      component: LogIn
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: Forgot,
    },
    {
      path: '/chat',
      name: 'chat',
      component: Chat,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reset/:token',
      name: 'reset',
      component: Reset,
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/lab-contact-form',
      name: 'lab-contact-form',
      component: LabContactForm
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: ConfirmationPage,
      props: true
    },
    {
      path: '/labs/:labId',
      name: 'lab-detail',
      component: ViewLab,
      props: true
    },
    {
      path: '/editlab/:labId',
      name: 'lab-edit',
      component: EditLab,
      props: true
    },
    {
      path: '/mylabs/:userId',
      name: 'mylabs',
      component: MyLabs,
      props: true,
      meta: {
        requiresAuth: true
      }
    },  
    {
      path: '/search',
      name: 'search',
      component: LabSearch,
      props: true
    }, 
    {
      path: '/createlab',
      name: 'create-lab',
      component: CreateLab,
      props: true
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isUserLoggedIn) {
      next({name: 'login'})
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router;