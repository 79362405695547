<template>
<v-container class="fill-height" fluid>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card tile class="elevation-12">
        <v-toolbar class="primary" dark>
          <v-toolbar-title>Forgot Password</v-toolbar-title>
        </v-toolbar>
        <v-alert
            :value="alert"
            v-if="this.error.length > 0" 
            color="red"
            dark
            border="top"
            transition="scale-transition"
          >
          {{error}}
        </v-alert>         
        <v-card-text>
          <v-form onSubmit="return false;">
            <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              label="E-mail"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              @keydown.enter="forgot"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-col xs12>
              <v-btn class="primary" tile block @click="forgot">submit</v-btn>
            </v-col>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout> 
</v-container>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  import AuthService from '@/services/AuthenticationService'
  export default {
    name: 'Forgot',
    mixins: [validationMixin],

    validations: {
      email: { required, email },
    },
    data: () => ({
      email: '',
      error: '',
      alert: false,
    }),
    computed: {
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
    },
    methods: {
      async forgot () {
        this.$v.$touch()
        if (this.$v.$invalid) {
            this.alert = true
            this.error = "Input fields are not valid"
            return;
        }
        try {
          const response = await AuthService.forgot({
            email: this.email
          })
          console.log(response)
          this.$router.push({name: 'confirmation', params: {
            message: "A password reset email has been sent to the email address " + this.email
          }})
        } catch (error) {
          this.alert = true
          this.error = error.response.data.error
          console.log(this.error)
        }
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
