<template>
<v-container class="fill-height" fluid>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card tile class="elevation-12">
        <v-toolbar class="primary" dark>
          <v-toolbar-title>Reset Password</v-toolbar-title>
        </v-toolbar> 
        <v-alert
            :value="alert"
            v-if="this.error.length > 0" 
            color="red"
            dark
            border="top"
            transition="scale-transition"
          >
          {{error}}
        </v-alert>         
        <v-card-text>
          <v-form>
            <v-text-field
            v-model="password"
            :error-messages="PasswordErrors"
            :type="'password'"
            required
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            ></v-text-field>

            <v-text-field
            v-model="repeatPassword"
            :error-messages="RepeatedPasswordErrors"
            :type="'password'"
            required
            label="Confirmed Password"
            counter
            @input="$v.repeatPassword.$touch()"
            @blur="$v.repeatPassword.$touch()"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-col xs12>
              <v-btn class="primary" tile block @click="submit">submit</v-btn>
            </v-col>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout> 
</v-container>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, sameAs, minLength } from 'vuelidate/lib/validators'
  import AuthService from '@/services/AuthenticationService'
  export default {
    name: 'Reset',
    mixins: [validationMixin],

    validations: {
      password: {required, minLength: minLength(8)},
      repeatPassword: {sameAsPassword: sameAs('password')},
    },
    data: () => ({
      show1: false,
      password: '',
      repeatPassword: '',
      error: '',
      alert: false,
    }),
    computed: {
      PasswordErrors() {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('Password is required')
        !this.$v.password.minLength && errors.push('Password has to be at least 8 charactors')
        return errors
      },
      RepeatedPasswordErrors() {
        const errors = []
        if (!this.$v.repeatPassword.$dirty) return errors
        !this.$v.repeatPassword.sameAsPassword && errors.push('Confirmed password should be the same as above')
        return errors
      }
    },
    methods: {
      async submit () {
        this.$v.$touch()
        if (this.$v.$invalid) {
            this.alert = true
            this.error = "Input fields are not valid"
            return;
        }
        try {
          const response = await AuthService.reset({
            password: this.password,
            repeatPassword: this.repeatPassword,
            token: this.$route.params.token
          });

          console.log(response);
          this.$router.push({
            name: 'login'
          });
        } catch (error) {
          this.alert = true
          this.error = error.response.data.error
        }
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
