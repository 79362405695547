<template>
<v-container class="fill-height" fluid>
  <v-row align="start" justify="center">
    <v-col             
      cols="6">
    <v-form>
    <v-text-field
      v-model="lab.name"
      label="Lab Name"
    ></v-text-field>

    <v-text-field
    v-model="lab.website"
    label="Website"
    ></v-text-field>

    <v-text-field
      v-model="lab.address1"
      label="Address1"
    ></v-text-field>

    <v-text-field
    v-model="lab.address2"
    label="Address2"
    ></v-text-field>

    <v-text-field
    v-model="lab.city"
    label="City"
    ></v-text-field>

    <v-text-field
    v-model="lab.state"
    label="State"
    ></v-text-field>

    <v-text-field
    v-model="lab.zipcode"
    label="Zip Code"
    ></v-text-field>

    <v-text-field
    v-model="lab.country"
    label="Country"
    ></v-text-field>

    <v-subheader class="title font-weight-bold">Lab Information</v-subheader>

    <v-textarea
      v-model="lab.description"
      label="Description"
      auto-grow
    ></v-textarea>

    <div>
      <h4 class="my-3"> Category </h4>
      <div class="category-lists" v-if="categoryList.length">
        <ul>
          <li v-for="(item, index) in categoryList" :key="index">
            <span class="body dynamic-items" contenteditable="true" v-on:keydown.enter="updateItemList($event, item)" v-on:blur="updateItemList($event, item)">{{item.title}}</span> 
            <span class="remove" v-on:click="removeItem(categoryList, index)">x</span></li>
        </ul>
      </div>
        <form name="category-form" method="post" action="" v-on:submit.prevent="addCategory">
        <input name="add-category" v-model="addCategoryInput" type="text"/>
        <button class="item-btn ml-5" type="submit">Add</button>
        </form>
    </div>

    <v-divider class="my-10"> </v-divider>
    <div class="mb-10">
      <h4 class="my-3"> Equipments </h4>
      <div class="equipment-lists" v-if="equipmentList.length">
        <ul>
          <li v-for="(item, index) in equipmentList" :key="index">
            <span class="body dynamic-items" contenteditable="true" v-on:keydown.enter="updateItemList($event, item)" v-on:blur="updateItemList($event, item)">{{item.title}}</span> 
            <span class="remove" v-on:click="removeItem(equipmentList, index)">x</span></li>
        </ul>
      </div>
        <form name="equipment-form" method="post" action="" v-on:submit.prevent="addEquipment">
        <input name="add-equipment" v-model="addEquipmentInput" type="text"/>
        <button class="item-btn ml-5" type="submit">Add</button>
        </form>
    </div>

    <v-textarea
      v-model="lab.additionalInfo"
      label="Additional Information"
      auto-grow
    ></v-textarea>

    <v-btn class="mr-4" @click="save">submit</v-btn>
    </v-form>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
  import LabService from '@/services/LabsService'
  // import { validationMixin } from 'vuelidate'
  // import { required } from 'vuelidate/lib/validators'
  export default {
    name: 'CreateLab',
    data: () => ({
      lab: {
        id: null,
        name: null,
        description: null,
        additionalInfo: null,
        category: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipcode: null,
        country: null,
        website: null,
        equipments: null,
      },
      categoryList: [],
      addCategoryInput: '',
      equipmentList: [],
      addEquipmentInput: '',
    }),
    computed: {
    },
    async mounted () {
      try {
        const labId = this.$store.state.route.params.labId
        this.lab = (await LabService.show(labId)).data
        this.categoryList = this.lab.category.map(function(x) {
          return {title: x};
        })
        this.equipmentList = this.lab.equipments.map(function(x) {
          return {title: x};
        })
      } catch (err) {
        console.log(err)
      }
    },
    methods: {
      async save () {
        try {
          this.lab.category = this.categoryList.map(x => x.title)
          this.lab.equipments = this.equipmentList.map(x => x.title)
          await LabService.update(this.lab)
          this.$router.push({
            name: 'lab-detail', 
            params: {
              labId: this.$store.state.route.params.labId
            }
          })
        } catch (error) {
          this.error = error.response.data.error
        }
      },
      addCategory: function(){
      this.categoryList.push({title: this.addCategoryInput});
      this.addCategoryInput = '';
      },
      addEquipment: function(){
        this.equipmentList.push({title: this.addEquipmentInput});
        this.addEquipmentInput = '';
      },
      removeItem: function(list, index){
        list.splice(index, 1);
      },
      updateItemList: function(e, item){
        e.preventDefault();
        item.title = e.target.innerText;
        e.target.blur();
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type=text]{
  font-size:16px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}

[contenteditable=true]:focus{
  
  
  overflow: hidden;
  border: 1px solid transparent;

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;

  white-space: nowrap;
  border-radius: 10px;
}

.dynamic-items{
  display: inline-block;
  width: 200px;
  border: 1px solid transparent;
  padding: 8px;
  font-size: 16px;
  vertical-align:middle;
}

.dynamic-items:hover{
  border:1px solid #c4c4c4;
  border-radius: 10px;
}

.remove{
  cursor:pointer;
  display:inline-block;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  padding:0px 4px;
}
.remove:hover{
  background: #3cb0fd;
}

.item-btn{
  background: #3498db;
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  border-radius: 28px;
  
  color: #ffffff;
  font-size: 16px;
  padding: 8px 20px;
  border: none;
  cursor:pointer;
}
.item-btn:hover {
  background: #3cb0fd;
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
}
</style>
