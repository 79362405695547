<template>
    <v-app-bar
      app
      color="primary lighten-3"
      dark
    >
    <span
      class="clickable"
      @click="navigateTo({name: 'home'})">   
      <v-toolbar-title>
        <img
          :src="`${publicPath}logo.ico`"
        >
        LabLocale
      </v-toolbar-title>
    </span>

    <v-spacer></v-spacer>

    <!-- <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
    <v-toolbar-items>
      <v-btn 
        v-if="$store.state.isUserLoggedIn" 
        @click="navigateTo({name: 'create-lab'})"
        text>
        Add Lab
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-btn 
        v-if="$store.state.isUserLoggedIn" 
        @click="mylabs" 
        text>
        My Labs
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-btn 
        v-if="!$store.state.isUserLoggedIn"
        @click="navigateTo({name: 'login'})"
        text>
        Log In
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-btn 
        v-if="!$store.state.isUserLoggedIn" 
        @click="navigateTo({name: 'register'})"
        text>
        Sign Up
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-btn 
        v-if="$store.state.isUserLoggedIn" 
        @click="logout" 
        to="/"
        text>
        Log Out
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
  export default {
    data () {
      return {
        publicPath: process.env.BASE_URL
      }
    },
    methods: {
      navigateTo (route) {
        this.$router.push(route)
      },
      mylabs () {
        this.$router.push({name: 'mylabs',
                      params: {
                        userId: this.$store.getters.user.id,
                      }})
      },
      logout () {
        this.$store.dispatch('setToken', null)
        this.$store.dispatch('setUser', null)
        this.$router.push({
          name: 'home'
        })
      }
    },
  }
</script>
<style scoped>
.clickable {
  cursor:pointer;
  color:black;
}

.clickable:hover {
  /*color: #2196F3;*/
  color:white;
}
</style>
