<template>
<v-container class="fill-height" fluid>
  <v-row dense align="start" justify="center">
  <v-col cols="6">
  <v-card
      class="mx-auto"
    >
    <v-form
      class="pa-4 pt-6"
    >

    <v-text-field
      v-model="first_name"
      label="First Name"
      required
      filled
      style="min-height: 96px"
    ></v-text-field>

    <v-text-field
      v-model="last_name"
      label="Last Name"
      required
      filled
      style="min-height: 96px"
    ></v-text-field>

    <v-text-field
      v-model="email"
      label="E-mail"
      required
      filled
      style="min-height: 96px"
    ></v-text-field>

    <v-textarea
      v-model="message"
      auto-grow
      filled
      style="min-height: 96px"
      label="Message"
      rows="2"
    ></v-textarea>
    <v-card-actions>
    <v-btn class="mr-4" @click="sendEmail">submit</v-btn>
    <v-btn class="mr-4" @click="goBack">cancel</v-btn>
    </v-card-actions>

    </v-form>
  </v-card>
  </v-col>
  </v-row>
</v-container>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data: () => ({
    lab: {},
    first_name: '',
    last_name: '',
    email: '',
    message: '',
  }),
  methods: {
    get_fullname(first, last) {
      return first + " " + last
    },
    goBack() {
      this.$router.back()
    },
    sendEmail() {
      this.lab = this.$store.state.route.params.lab
      this.lab_admin = this.$store.state.route.params.lab_admin
      try {
        emailjs.send("service_r4av1z3","template_7rdue6g",{
          from_name: this.get_fullname(this.first_name, this.last_name),
          from_email: this.email,
          message: this.message,
          lab_name: this.lab.name,
          lab_admin: this.get_fullname(this.lab_admin.firstname, this.lab_admin.last_name),
          lab_email: this.lab_admin.email,
        }, "user_FrI1Mh9QbUkN5qiTpLFy3");
      } catch(error) {
        console.log({error})
      }
      this.$router.push({name: 'confirmation', params: {
        message: "Thanks for sending us the request. We will get back to you soon."
      }})
    }
  }
}
</script>
<style scoped>
</style>