<template>

      <v-parallax
        src="../assets/pic/background-labs.png"
        dark
        height="100%"
        fluid class="fill-height"
      > 
      <v-row gutters justify="center">
        <v-col cols="3">
          <v-text-field
            hide-details
            label="Search equipment, lab services or name"
            prepend-icon="search"
            v-model="query"
            @keydown.enter="search"
            @click:prepend="search"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            hide-details
            label="Zip Code"
            prepend-icon="search"
            v-model="zipcode"
            @keydown.enter="search"
            @click:prepend="search"
          ></v-text-field>
        </v-col>
      </v-row>
        <v-row gutters>
          <v-col md="12" lg="2">
             <filter-panel /> 
          </v-col>
          <v-col md="12" lg="8">
            <v-container fluid class="fill-height" >
                <lab-panel :labs="labs" :timeout="timeout"/>
                <v-pagination
                  circle
                  v-model="page"
                  :length="totalPages"
                  total-visible="7"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                  @input="handlePageChange"
                ></v-pagination>
            </v-container>

          </v-col>
        </v-row> 
      </v-parallax>
</template>

<script>
  import FilterPanel from './FilterPanel'
  import LabPanel from './LabPanel'
  import LabService from '@/services/LabsService'
  export default {
    components: {
      FilterPanel,
      LabPanel
    },
    data () {
      return {
        query: '',
        zipcode: '',
        lab_types: [],
        labs: null,
        page: 1,
        totalPages: 0,
        pageSize: 5,
        timeout: 100
      }
    },
    async mounted () {
      this.retrieveLabs();
    },
    methods: {
      search() {
        let query = {
            page: this.page,
            size: this.pageSize,
        }
        if (this.query != '') {
          query['query'] = this.query
          query['search'] = true
        }
        if (this.zipcode != '') {
          query['zipcode'] = this.zipcode
          query['search'] = true
        }
        const selectedTypes = this.$store.state.search_types
        if (selectedTypes && selectedTypes.length != 0) {
          query['lab_types'] = selectedTypes
          query['search'] = true
        }
        this.$router.push({path: '/',
              query: query})
      },
      retrieveLabs() {
        if (Object.keys(this.$store.state.route.query).length != 0) {
          this.page = this.$store.state.route.query.page
          this.pageSize = this.$store.state.route.query.size
          if (this.$store.state.route.query.query) {
            this.query = this.$store.state.route.query.query
          }
          if (this.$store.state.route.query.zipcode) {
            this.zipcode = this.$store.state.route.query.zipcode
          }
          if (this.$store.state.route.query.lab_types) {
            this.lab_types = this.$store.state.route.query.lab_types
          }
        }
        let params = {
          page: this.page - 1,
          size: this.pageSize
        }
        if (this.$store.state.route.query.search) {
          if (this.query != '') {
            params['raw_query'] = this.query
          }
          if (this.zipcode != '') {
            params['zipcode'] = this.zipcode
          }
          if (this.lab_types && this.lab_types.length != 0) {
            params['lab_types'] = this.lab_types
          }
          LabService.search(params).then((response) => {
            this.labs = response.data.labs
            this.totalPages = response.data.totalPages
          }).catch(() => {
            this.labs = []
          })
        } else {
          LabService.getAll(params).then((response) => {
            this.labs = response.data.labs
            this.totalPages = response.data.totalPages
          }).catch(() => {
            this.labs = []
          }) 
        }
      },
      handlePageChange (page) {
        this.page = page;
        this.search();
      }
    },
    watch: {
      '$store.state.search_types': function (types) {
        this.lab_types = types;
        this.search();
      }
    }
  };
</script>


<style type="text/css">
  .v-parallax__image {
    width: 100% !important;
  }
</style>
