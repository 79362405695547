<template>
  <v-container class="fill-height" fluid>
    <v-layout align-center justify-center>
      <v-flex sm12 md8>
        <v-card tile class="elevation-12">
          <v-toolbar class="info" dark>
            <v-toolbar-title>{{ is_create?'Add Lab':'Update Lab' }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="confirm_delete_dialog"
              width="500"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!is_create"
                color="red"
                v-bind="attrs"
                v-on="on"
                >
                Delete
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Confirm to delete?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  @click="deleteLab"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          </v-toolbar>        
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="labName"
                label="Lab Name"
              ></v-text-field>
              <div v-if="!$v.labName.required" class="invalid-feedback">Lab Name is required</div>

              <v-text-field
              v-model="website"
              label="Website"
              ></v-text-field>

              <v-text-field
                v-model="address1"
                label="Address1"
              ></v-text-field>

              <v-text-field
              v-model="address2"
              label="Address2"
              ></v-text-field>

              <v-text-field
              v-model="city"
              label="City"
              ></v-text-field>

              <v-text-field
              v-model="state"
              label="State"
              ></v-text-field>

              <v-text-field
              v-model="zipcode"
              label="Zip Code"
              ></v-text-field>
              <div v-if="!$v.zipcode.required" class="invalid-feedback">Zipcode is required</div>

              <v-text-field
              v-model="country"
              label="Country"
              ></v-text-field>
              <!-- <v-subheader class="title font-weight-bold">Lab Information</v-subheader> -->
              <v-textarea
                v-model="description"
                label="Lab Description"
                placeholder="This lab is a fantastic place for ..."
                auto-grow
              ></v-textarea>

              <v-divider class="my-10"> </v-divider>
              <div class="mb-10">
                <h4 class="my-3"> Lab Types </h4>
                  <v-list-item-group                    
                    multiple
                    v-model="selectedTypes"
                  >
                    <v-list-item
                      v-for="type in labTypes"
                      :key="type.id"
                      :value="type"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox 
                            :input-value="active"
                            :true-value="type"
                          >                            
                          </v-checkbox>
                        </v-list-item-action>
            
                        <v-list-item-content>
                          <v-list-item-title v-text="type"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
              </div>

              <v-divider class="my-10"> </v-divider>
              <div class="mb-10">
                <h4 class="my-3"> Equipments </h4>
                <div class="equipment-lists" v-if="equipmentList.length">
                  <ul>
                    <li v-for="(item, index) in equipmentList" :key="index">
                      <span class="body dynamic-items" contenteditable="true" v-on:keydown.enter="updateItemList($event, item)" v-on:blur="updateItemList($event, item)">{{item.title}}</span> 
                      <span class="remove" v-on:click="removeItem(equipmentList, index)">x</span></li>
                  </ul>
                </div>
                  <form name="equipment-form" method="post" action="" v-on:submit.prevent="addEquipment">
                  <input name="add-equipment" v-model="addEquipmentInput" type="text"/>
                  <v-btn class="info ml-5 mb-1" tile type="submit">Add</v-btn>
                  </form>
              </div>

              <v-divider class="my-10"> </v-divider>
              <div class="mb-10">
                <h4 class="my-3"> Research Service Provided </h4>
                <div class="service-lists" v-if="serviceList.length">
                  <ul>
                    <li v-for="(item, index) in serviceList" :key="index">
                      <span class="body dynamic-items" contenteditable="true" v-on:keydown.enter="updateItemList($event, item)" v-on:blur="updateItemList($event, item)">{{item.title}}</span> 
                      <span class="remove" v-on:click="removeItem(serviceList, index)">x</span></li>
                  </ul>
                </div>
                  <form name="service-form" method="post" action="" v-on:submit.prevent="addService">
                  <input name="add-service" v-model="addServiceInput" type="text"/>
                  <v-btn class="info ml-5 mb-1" tile type="submit">Add</v-btn>
                  </form>
              </div>

              <v-divider class="my-10"> </v-divider>
              <p>Upload Images</p>
              <S3FileUpload :obj="images" :inputFiles="uploaded_images" fieldName="images" />
              
              <v-divider class="my-10"> </v-divider>
              <div>
                <h4 class="my-3"> Tags (no more than 5) </h4>
                <div class="category-lists" v-if="categoryList.length">
                  <ul>
                    <li v-for="(item, index) in categoryList" :key="index">
                      <span class="body dynamic-items" contenteditable="true" v-on:keydown.enter="updateItemList($event, item)" v-on:blur="updateItemList($event, item)">{{item.title}}</span> 
                      <span class="remove" v-on:click="removeItem(categoryList, index)">x</span></li>
                  </ul>
                </div>
                  <form name="category-form" method="post" action="" v-on:submit.prevent="addCategory">
                  <input name="add-category" v-model="addCategoryInput" type="text"/>
                  <v-btn class="info ml-5 mb-1" tile type="submit">Add</v-btn>
                  </form>
              </div>

              <v-divider class="my-10"> </v-divider>
              <v-textarea
                v-model="priceInfo"
                label="Pricing Information"
                placeholder="Include your pricing here"
                auto-grow
              ></v-textarea>

              <v-divider class="my-10"> </v-divider>
              <v-textarea
                v-model="additionalInfo"
                label="Additional Information"
                placeholder="More information you want for people to know."
                auto-grow
              ></v-textarea>
            </v-form>
          </v-card-text>  
          <v-card-actions>
              <v-col xs12>
                <v-btn block tile class="info" @click="submit">Submit</v-btn>
              </v-col>
              <v-col xs12>
                <v-btn block tile @click="goBack">Cancel</v-btn>
              </v-col>
          </v-card-actions>
          <v-alert
            :value="alert"
            color="red"
            dark
            border="top"
            icon="mdi-home"
            transition="scale-transition"
          >
          {{error}}
          </v-alert>
        </v-card>
      </v-flex>
    </v-layout> 
  </v-container>
</template>

<script>
  import LabService from '@/services/LabsService'
  import S3FileUpload from './S3FileUpload'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  export default {
    mixins: [validationMixin],
    components: {
      S3FileUpload
    },
    props: ['msg'],
    name: 'CreateLab',
    data () { 
      return {
      labName: '',
      description: '',
      priceInfo: '',
      additionalInfo: '',
      images: {},
      uploaded_images: [],
      categoryList: [],
      addCategoryInput: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      website: '',
      notes: '',
      equipmentList: [],
      addEquipmentInput: '',
      serviceList: [],
      addServiceInput: '',
      labTypes: ['Lab Leasing', 
                  'Incubator/Accelerator', 
                  'Other Shared Spaces',
                  'Research Services Offered',
                  'Properties'],  
      selectedTypes: [],
      error: "",
      alert: false,
      is_create: true,
      s3Url: 'https://labspace-images.s3.amazonaws.com/',
      confirm_delete_dialog: false
      }
    },
    validations: {
      labName: {
        required
      },
      zipcode: {
        required
      }
    },
    async mounted() {
      if (this.msg) {
        this.labName = this.msg.name,
        this.description = this.msg.description,
        this.additionalInfo = this.msg.notes,
        this.priceInfo = this.msg.price,
        this.uploaded_images = this.msg.image_urls.map(x => x.replace(this.s3Url, ""))
        this.categoryList = this.msg.category.map(x => {return {title: x}}),
        this.address1 = this.msg.address1,
        this.address2 = this.msg.address2,
        this.city = this.msg.city,
        this.state = this.msg.state,
        this.zipcode = this.msg.zipcode,
        this.country = this.msg.country,
        this.website = this.msg.website,
        this.equipmentList = this.msg.equipments.map(x => {return {title: x}}),
        this.serviceList = this.msg.services.map(x => {return {title: x}})
        this.selectedTypes = this.msg.lab_type
        this.is_create = false
      }
    },
    methods: {
        getLabData() {
          return {
            name: this.labName,
            description: this.description,
            notes: this.additionalInfo,
            price: this.priceInfo,
            category: this.categoryList.map(x => x.title),
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            country: this.country,
            image_urls: this.images["urls"],
            website: this.website,
            equipments: this.equipmentList.map(x => x.title),
            services: this.serviceList.map(x => x.title),
            lab_types: this.selectedTypes,
            UserId: this.$store.state.user.id,
          }
        },
        createOrUpdateLab (is_create) {
          this.$v.$touch();
          if (this.$v.$invalid) {
              this.alert = true
              this.error = "Input fields are not valid"
              return;
          }
          if (is_create) {
            LabService.create(this.getLabData()).then((response) => {
              this.$router.replace({
                name: 'lab-detail', 
                params: {
                  labId: response.data.id
                }
              })
            }).catch((error) => {
              console.error(error)
              this.alert = true
              this.error = error.response.data.error
            })
          } else {
            if (this.msg) {
              var data = this.getLabData();
              data.id = this.msg.id
              LabService.update(data).then((response) => {
                this.$router.replace({
                  name: 'lab-detail', 
                  params: {
                    labId: response.data
                  }
                })
              }).catch((error) => {
                console.error(error)
                this.alert = true
                this.error = error.response.data.error
              })
            }
          }

      },
      submit() {
          this.createOrUpdateLab(this.is_create)
      },
      deleteLab() {
        this.confirm_delete_dialog = false
        if (this.msg) {
          LabService.delete(this.msg.id).then((response) => {
            if (response.status === 200) {
              this.$router.replace({name: 'mylabs',
                      params: {
                        userId: this.$store.state.user.id,
                      }})
            }
          }).catch((error) => {
            console.error(error)
            this.alert = true
            this.error = error.response.data.error
          })
        }
      },
      clear () {
        this.$v.$reset()
        this.labName= ''
        this.description= ''
        this.additionalInfo= ''
        this.categoryList= []
        this.images={}
        this.addCategoryInput= ''
        this.address1= ''
        this.address2= ''
        this.city= ''
        this.state= ''
        this.zipcode= ''
        this.country= ''
        this.website= ''
        this.notes= ''
        this.equipmentList= []
        this.addEquipmentInput= ''
        this.error = ""
        this.alert = false
      },
      goBack() {
        this.$router.back()
      },
      addCategory: function(){
      if (this.categoryList.length >= 5) {
        this.addCategoryInput = '';
        return
      }
      this.categoryList.push({title: this.addCategoryInput});
      this.addCategoryInput = '';
      },
      addEquipment: function(){
        this.equipmentList.push({title: this.addEquipmentInput});
        this.addEquipmentInput = '';
      },
      addService: function(){
        this.serviceList.push({title: this.addServiceInput});
        this.addServiceInput = '';
      },
      removeItem: function(list, index) {
        list.splice(index, 1);
      },
      updateItemList: function(e, item) {
        e.preventDefault();
        item.title = e.target.innerText;
        e.target.blur();
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type=text]{
  font-size:16px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}

[contenteditable=true]:focus{
  
  
  overflow: hidden;
  border: 1px solid transparent;

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;

  white-space: nowrap;
  border-radius: 10px;
}

.dynamic-items{
  display: inline-block;
  width: 200px;
  border: 1px solid transparent;
  padding: 8px;
  font-size: 16px;
  vertical-align:middle;
}

.dynamic-items:hover{
  border:1px solid #c4c4c4;
  border-radius: 10px;
}

.remove{
  cursor:pointer;
  display:inline-block;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  padding:0px 4px;
}
.remove:hover{
  background: #3cb0fd;
}

.item-btn{
  background: #3498db;
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  border-radius: 28px;
  
  color: #ffffff;
  font-size: 16px;
  padding: 8px 20px;
  border: none;
  cursor:pointer;
}
.item-btn:hover {
  background: #3cb0fd;
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
}
.invalid-feedback {
  color: red;
}
</style>
